<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin>
        <div class="hader-top-min-1">
          <span></span>
          <div class="fl logo htm1">关于我们</div>
        </div>
      </headerMin>
    </div>
    <div class="wrap content aboutus-content">
      <!-- <div class="img">
        <img :src="config.app_android_url" alt />
      </div> -->
      <p style="text-align:center;margin:20px 0px"><strong><span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">联众健康</span></span></strong><strong><span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">关于我们</span></span></strong></p>

      <div ref='txt' class="txt"></div>
    </div>
    <publicBottom></publicBottom>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/public/public_headerMin.vue";
import publicBottom from "@/components/public/public_bottom.vue";
import { getSysconfs } from "@/request/public";
export default {
  components: {
    headerMin,
    publicBottom,
    headerTop
  },
  data() {
    return {
      config: {},
      app_show: 0
    };
  },
  created() {
    getSysconfs({ token: this.$token }).then(res => {
      if (res.code == 1) {
        this.config = res.data;
        this.$refs.txt.innerHTML = this.config.aboutus
      }
    });
  }
};
</script>
<style lang="less" scoped>
.hader-top-min-1 {
  span {
    float: left;
    height: 38px;
    width: 1px;
    background: #999;
    margin-left: 20px;
    margin-top: 27px;
  }
  .htm1 {
    padding-left: 20px;
    font-size: 38px;
    margin-top: -6px;
    margin-left: 0px;
  }
}
</style>